import { isEmpty, find } from "lodash";

/**
 * Creative Bulk
 * Init Model Data
 * @returns
 */
export function initCreativeBulk() {
	return {
		creative_template_id: null,
		file: null,
		name_prefix: "",
		secure: true,
		creative_advertiser: {
			advertiser_id: "",
			domain: "",
			category_id: "",
		},
		creative_ad_content: {
			creative_rule_id: null,
			click_url: "",
		},
		creative_exchange_options: {
			landing_page_url: "",
			appnexus_submit: false,
			thumbnail_id: null,
			fileThumbnail: null,
			thumbnail_url: "",
		},

		creative_addon_settings: {
			addons: [],
			pixels: [],
			scripts: [],
			tmp: {
				addons: "",
				pixels: "",
				scripts: "",
			},
		},
		creative_attributes: {
			mime_type_id: null,
			in_banner_video_id: null,
		},
	};
}

export function matchedPaths() {
	return {
		thumbnail_id: "creative/getDetectAttributes",
	};
}

/**
 * Creative Config
 * @returns
 */
export function createCreativeConfig() {

	return {
		thumbnail_url: "",
	};
}

/**
 * Creative Bulk
 * Init Resources
 * @returns
 */
export function initResources() {
	return {
		creative_template_id: {},
		secure: {},
		name_prefix: {},
		creative_advertiser: {
			advertiser_id: {},
			domain: {},
			category_id: {},
		},
		creative_ad_content: {
			creative_rule_id: {},
			click_url: {},
		},
		file: {},
		creative_exchange_options: {
			landing_page_url: {},
			appnexus_submit: {},
			thumbnail_id: {},
			fileThumbnail: {},
			thumbnail_url: {},
		},
		creative_addon_settings: {
			addons: {},
			pixels: {},
			scripts: {},
		},
		creative_attributes: {
			mime_type_id: {},
			in_banner_video_id: {},
		},
	};
}

/**
 * Creative Bulk
 * Default Filters & Options
 * @returns
 */
export function getBulkFilterOptions() {
	return {
		filter_advertiser: {
			filters: { active: true },
			options: {
				sort: "name",
				order: "asc",
			},
		},
		filter_creative_addon: {
			filters: { creative_type_id: 21 },
		},
	};
}

/**
 * Default bg color
 */
export const defaultBgColor = "#eee";

/**
 * Get the accepted extension of the file
 * @param isJs
 * @returns
 */
export async function getAcceptFile(isJs: Boolean = false): Promise<any> {
	return isJs ? ["text/csv", "application/vnd.ms-excel"] : ["application/x-zip-compressed", "application/zip"];
}

export function getSupportedExt(isJs: Boolean = false): string {
	return isJs ? ".csv" : ".zip";
}

export async function isValidFile(file: any): Promise<Boolean> {
	return "File" in window && file instanceof File;
}

export async function isValidAcceptFile(
	file: any,
	isJs: Boolean = false
): Promise<Boolean> {
	const is_accept = await getAcceptFile(isJs);
	const file_type = file?.type;
	const val = is_accept.find((f: any) => f == file_type);
	return (
		"File" in window &&
		file instanceof File &&
		//is_accept.find((f: any) => f == file_type)
		file_type.includes(val)
	);
}

export async function isValidAcceptFileThumbnail(
	file: any,
	isJs: Boolean = false
): Promise<Boolean> {
	const validImageTypes = [
		"image/png",
		"image/jpeg",
		"image/bmp",
		"image/gif",
		"image/jpg"
	];
	const file_type = file?.type;
	const isValidType = validImageTypes.includes(file_type);

	return (
		"File" in window &&
		file instanceof File &&
		isValidType
	);
}

/**
 * Prepare formData for create
 * @param model
 * @returns
 */
export async function prepareCreateFormData(model: any) {
	let formData = new FormData();

	formData.append("creative_template_id", model.creative_template_id);

	formData.append("name_prefix", model.name_prefix);

	formData.append("secure", model.secure ? "1" : "0");

	formData.append(
		"creative_advertiser[advertiser_id]",
		String(model.creative_advertiser.advertiser_id)
	);

	formData.append(
		"creative_advertiser[domain]",
		String(model.creative_advertiser.domain)
	);

	formData.append(
		"creative_advertiser[category_id]",
		String(model.creative_advertiser.category_id)
	);

	formData.append(
		"creative_ad_content[creative_rule_id]",
		String(model.creative_ad_content.creative_rule_id)
	);

	formData.append(
		"creative_ad_content[click_url]",
		String(model.creative_ad_content.click_url)
	);

	formData.append(
		"creative_exchange_options[landing_page_url]",
		String(model.creative_exchange_options.landing_page_url)
	);

	formData.append(
		"creative_exchange_options[appnexus_submit]",
		model.creative_exchange_options.appnexus_submit ? "1" : "0"
	);

	formData.append(
		"creative_exchange_options[thumbnail_id]",
		model.creative_exchange_options.thumbnail_id
	);

	formData.append(
		"creative_attributes[in_banner_video_id]",
		model.creative_attributes.in_banner_video_id
	);

	if (!isEmpty(model.creative_addon_settings.addons)) {
		model.creative_addon_settings.addons.forEach(
			(addon: any, index: string) => {
				formData.append(
					"creative_addon_settings[addons][" + index + "]",
					String(addon)
				);
			}
		);
	}

	if (!isEmpty(model.creative_addon_settings.pixels)) {
		model.creative_addon_settings.pixels.forEach(
			(pixel: any, index: string) => {
				formData.append(
					"creative_addon_settings[pixels][" + index + "]",
					String(pixel)
				);
			}
		);
	}

	if (!isEmpty(model.creative_addon_settings.scripts)) {
		model.creative_addon_settings.scripts.forEach(
			(script: any, index: string) => {
				formData.append(
					"creative_addon_settings[scripts][" + index + "]",
					String(script)
				);
			}
		);
	}

	formData.append("file", model.file);

	return formData;
}
